import { parseRFC5646Tag } from './locale';
import crawlerList from '../supportedCrawlers';

export function browserLanguage() {
  return parseRFC5646Tag(navigator.language);
}

export function isCrawler() {
  if (typeof document === 'undefined') {
    return true;
  }

  for (let index = 0; index < crawlerList.length; index++) {
    const crawler = crawlerList[index];
    const tester = new RegExp(crawler.pattern).test(navigator.userAgent);
    if (tester) {
      return true;
    }
  }

  return false;
}
