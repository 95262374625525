import { types } from 'mobx-state-tree';

import Price from './Price';
import ShippingOptionSelectionField from './ShippingOptionSelectionField';
import { ProductShippingClassType } from '../types/ProductShippingClass';
import ShippingCampaignInfo from './ShippingCampaignInfo';
import Service from './Service';
import DeliveryTime from './DeliveryTime';

const ShippingOption = types
  .model('ShippingOption', {
    additional_services: types.array(Service),
    campaign_info: types.maybeNull(ShippingCampaignInfo),
    class: ProductShippingClassType,
    delivery_time: types.maybeNull(DeliveryTime),
    description: types.string,
    has_area_limitations: types.boolean,
    has_starting_price: types.boolean,
    id: types.identifierNumber,
    image: types.maybeNull(types.string),
    includes_low_order_fee: types.maybeNull(Price),
    low_order_fee_limit: types.maybeNull(Price),
    name: types.maybeNull(types.string),
    pickup_point_integration: types.maybeNull(types.string),
    price: Price,
    requires_pickup_point: types.boolean,
    selection_fields: types.array(ShippingOptionSelectionField),
  })
  .views((self) => {
    return {
      get selectionFields() {
        return self.selection_fields;
      },
      getDefaultSelectionField() {
        return (
          (self.selection_fields &&
            self.selection_fields.length > 0 &&
            self.selection_fields[0] &&
            self.selection_fields[0].options.length > 0 &&
            self.selection_fields[0].options[0]) ||
          null
        );
      },
      getPrice() {
        return self.price && self.price.with_tax;
      },
    };
  });

export default ShippingOption;
