import { types } from 'mobx-state-tree';

import OrderShipping from './OrderShipping';
import RecurringOrderProduct from './RecurringOrderProduct';
import OrderTotalValue from './OrderTotalValue';
import { OrderStatusType } from '../types/OrderStatusClass';

const ProductRecurringOrder = types.model('ProductRecurringOrder', {
  id: types.identifierNumber,
  title: types.maybeNull(types.string),
  autogenerated_title: types.string,
  recurring_status: OrderStatusType,
  allow_under_delivery: types.boolean,
  allow_shipping_method_change: types.boolean,
  on_hold_until: types.maybeNull(types.string),
  delivery_address_id: types.maybeNull(types.number),
  shipping: types.optional(OrderShipping, {}),
  created_at: types.string,
  updated_at: types.string,
  start_at: types.string,
  products: types.array(RecurringOrderProduct),
  totals: types.array(OrderTotalValue)
});

export default ProductRecurringOrder;
