import { types } from 'mobx-state-tree';

import OrderProduct from './OrderProduct';
import OrderPayment from './OrderPayment';
import OrderShipping from './OrderShipping';
import OrderTotalValue from './OrderTotalValue';
import OrderStatusHistoryEntry from './OrderStatusHistoryEntry';
import { OrderStatusType } from '../types/OrderStatusClass';

const Order = types
  .model('Order', {
    id: types.identifierNumber,
    date_purchased: types.string,
    last_status: OrderStatusType,
    last_modified: types.string,
    weight: types.maybeNull(types.string),
    seller_name: types.maybeNull(types.string),
    type: types.string,
    external_type: types.maybeNull(types.string),
    receipt_url: types.maybeNull(types.string),
    your_reference: types.maybeNull(types.string),
    no_taxes: types.boolean,
    products: types.array(OrderProduct),
    payment: types.optional(OrderPayment, {}),
    shipping: types.optional(OrderShipping, {}),
    totals: types.array(OrderTotalValue),
    status_history: types.array(OrderStatusHistoryEntry),
  })
  .views((self) => {
    return {
      getOrderReference: () => {
        return self.your_reference;
      },
      getCustomerInfo: () => {
        return self.payment?.address;
      },
      getCustomerShippingInfo: () => {
        return self.shipping?.address;
      },
    };
  });

export default Order;
