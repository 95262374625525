import React, { forwardRef, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-slick';
import { observer } from 'mobx-react';

import SliderNextArrow from './SliderNextArrow';
import SliderPreviousArrow from './SliderPreviousArrow';
import SSRAwareLazyLoad from '../SSRAwareLazyLoad';

const CommonSlider = forwardRef((props, ref) => {
  const {
    className,
    dots,
    lazyLoad,
    lazyLoadOptions,
    children,
    pauseOnHover,
    prevArrow,
    nextArrow,
    arrows,
    infinite,
    autoplay,
    magnified,
    isThumbnailSlider,
    slides,
    centerMode,
    variableWidth,
    ...rest
  } = props;

  const sliderRef = useRef(null);

  const getVariableWidth = () => {
    if (getSlides().length === 1 && centerMode) {
      return false;
    }

    return variableWidth;
  };

  const getSlides = useCallback(() => {
    if (slides) return slides;

    return children;
  }, [slides, children]);

  const slider = (
    <Slider
      ref={(innerRef) => {
        sliderRef.current = innerRef;

        if (ref) {
          ref.current = innerRef;
        }
      }}
      pauseOnHover={pauseOnHover || true}
      infinite={infinite || false}
      nextArrow={
        nextArrow || (
          <SliderNextArrow
            elementClassName={className ? `${className}__arrow` : ''}
            infinite={infinite}
            parentRef={sliderRef}
            isThumbnailSlider={isThumbnailSlider}
          />
        )
      }
      prevArrow={
        prevArrow || (
          <SliderPreviousArrow
            elementClassName={className ? `${className}__arrow` : ''}
            infinite={infinite}
            parentRef={sliderRef}
            isThumbnailSlider={isThumbnailSlider}
          />
        )
      }
      arrows={(arrows && getSlides().length > 1) || false}
      autoplay={(autoplay && getSlides().length > 1) || false}
      centerMode={
        (getVariableWidth() && centerMode && getSlides().length > 1) || false
      }
      variableWidth={getVariableWidth()}
      dots={(dots && getSlides().length > 1) || false}
      {...rest}
    >
      {getSlides()}
    </Slider>
  );

  const renderSlider = () => {
    /* TODO NNO-424 This seems to have no effect. Products are always loaded. Consider removal. */
    if (lazyLoad) {
      const options = {
        once: true,
        offset: 10,
        ...lazyLoadOptions,
      };

      return <SSRAwareLazyLoad {...options}>{slider}</SSRAwareLazyLoad>;
    } else {
      return slider;
    }
  };

  return (
    <div
      className={classNames(
        'Slider',
        {
          'Slider--with-dots': dots,
          'Slider--full-width': getSlides().length === 1 && !centerMode,
          'Slider--magnified': magnified,
        },
        className
      )}
    >
      {renderSlider()}
    </div>
  );
});

CommonSlider.propTypes = {
  className: PropTypes.string,
  arrows: PropTypes.bool,
  autoplay: PropTypes.bool,
  centerMode: PropTypes.bool,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  isThumbnailSlider: PropTypes.bool,
  lazyLoad: PropTypes.bool,
  magnified: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  variableWidth: PropTypes.bool,
  slides: PropTypes.array,
  lazyLoadOptions: PropTypes.object,
  nextArrow: PropTypes.object,
  prevArrow: PropTypes.object,
};

export default observer(CommonSlider);
