import { types } from 'mobx-state-tree';

import OrderMethod from './OrderMethod';
import OrderAddress from './OrderAddress';
import PickUpPointInfo from './PickUpPointInfo';

const OrderTracking = types.model('OrderTracking', {
  code: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

const OrderShipping = types.model('OrderShipping', {
  address: types.optional(OrderAddress, {}),
  method: types.maybeNull(types.optional(OrderMethod, {})),
  pick_up_point: types.maybeNull(PickUpPointInfo),
  tracking: types.optional(types.array(OrderTracking), []),
});

export default OrderShipping;
