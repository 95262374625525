import { types } from 'mobx-state-tree';

import OrderShipping from './OrderShipping';
import RecurringOrderProduct from './RecurringOrderProduct';
import OrderTotalValue from './OrderTotalValue';
import { OrderStatusType } from '../types/OrderStatusClass';
import { RecurringOrderCycleTypeType } from '../types/RecurringOrderCycleType';

const RecurringOrder = types.model('RecurringOrder', {
  allow_shipping_method_change: types.boolean,
  allow_under_delivery: types.boolean,
  autogenerated_title: types.string,
  created_at: types.string,
  cycles: types.array(RecurringOrderCycleTypeType),
  delivery_address_id: types.maybeNull(types.number),
  id: types.identifierNumber,
  min_custom_cycle: types.maybeNull(types.number),
  on_hold_until: types.maybeNull(types.string),
  products: types.array(RecurringOrderProduct),
  recurring_status: OrderStatusType,
  shipping: types.optional(OrderShipping, {}),
  start_at: types.string,
  title: types.maybeNull(types.string),
  totals: types.array(OrderTotalValue),
  updated_at: types.string
});

export default RecurringOrder;
