import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import { modelOf } from '../../../../prop-types';
import ConfigStore from '../../../../store/ConfigStore';
import AccountStore from '../../../../store/AccountStore';
import CurrencyStore from '../../../../store/CurrencyStore';
import RouteService from '../../../../services/RouteService';
import Product from '../../../../models/Product';
import { LocationContextPropType } from '../../../../services/LocationContext';
import useProductSchema from '../../../../hooks/schema/useProductSchema';
import SEOSchema from '../../../head/SEOSchema';

const ProductSchema = ({
  accountStore,
  configStore,
  currencyStore,
  routeService,
  locationContext,
  baseSchema,
  product,
}) => {
  const [getProductSchema] = useProductSchema({
    accountStore,
    configStore,
    currencyStore,
    routeService,
    locationContext,
  });

  const productSchema = getProductSchema(product);

  return (
    <SEOSchema
      data={{
        ...baseSchema,
        ...productSchema,
      }}
    />
  );
};

ProductSchema.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  product: modelOf(Product).isRequired,
  locationContext: LocationContextPropType.isRequired,
};

export default inject(
  'accountStore',
  'configStore',
  'currencyStore',
  'routeService',
  'locationContext'
)(observer(ProductSchema));
