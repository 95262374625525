import React, { Component, Fragment } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Button,
  Alert,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { get } from 'lodash';

import globalTranslations from '../../../i18n/globalTranslations';
import AccountStore from '../../../store/AccountStore';
import CouponStore from '../../../store/CouponStore';
import { modelOf } from '../../../prop-types';
import { isEmail } from '../../../util/formValidators';
import FormGroupField from '../../form/FormGroupField';
import { createForm } from '../../../models/Form';
import { createFormField } from '../../../models/FormField';
import PrivacyStatementLink from '../../privacy-statement/PrivacyStatementLink';

const VIEWS = {
  DATA: 'DATA',
  EMAIL: 'EMAIL',
};

@observer
export class CouponModal extends Component {
  state = {
    view: VIEWS.DATA,
    successMessage: null,
    errorMessage: null,
    sendCouponLoading: false,
    setCouponLoading: false,
    showEmailFormForRetrieve: false,
    retrieveCouponLoading: false,
    retrievedCode: null,
  };
  form = null;

  constructor(props) {
    super(props);
    this.form = createForm({
      email: createFormField({}, [
        isEmail(<FormattedMessage {...globalTranslations.emailTitle} />),
      ]),
    });
  }

  changeView = (view) => {
    this.setState({ view });
  };

  retrieveCoupon = () => {
    const { couponStore, activatedCoupon } = this.props;
    this.setState({ retrieveCouponLoading: true });
    couponStore
      .retrieveCoupon(
        activatedCoupon.coupon_id,
        activatedCoupon.activation_id,
        this.form.fields.get('email').value
      )
      .then((response) => {
        this.setState({
          errorMessage: null,
          retrievedCode: response.data.code,
          retrieveCouponLoading: false,
        });
      })
      .catch((error) => {
        const messages = get(error, 'response.data.messages', []);
        const errorMessage =
          messages.length > 0
            ? messages.map((message) => <div key={message}>{message}</div>)
            : this.getActivationError();
        this.setState({
          errorMessage,
          retrieveCouponLoading: false,
        });
      });
  };

  setCoupon = () => {
    const { couponStore, activatedCoupon } = this.props;
    this.setState({ setCouponLoading: true });
    const code = this.state.retrievedCode || activatedCoupon.code;
    couponStore
      .setCoupon(code)
      .then(() => {
        this.setState({
          successMessage: (
            <FormattedMessage
              id="coupon.setSuccess"
              defaultMessage="The campaign code was activated successfully!"
            />
          ),
          setCouponLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          errorMessage: this.getActivationError(),
          setCouponLoading: false,
        });
      });
  };

  sendCoupon = () => {
    const { couponStore, activatedCoupon } = this.props;
    this.setState({ sendCouponLoading: true });
    couponStore
      .sendCoupon(this.form.fields.get('email').value, activatedCoupon.code)
      .then(() => {
        this.setState({
          successMessage: (
            <FormattedMessage
              id="coupon.emailSuccess"
              defaultMessage="The discount coupon information has been sent to your email."
            />
          ),
          sendCouponLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          errorMessage: (
            <FormattedMessage
              id="coupon.emailError"
              defaultMessage="The information could not be sent to your email. Please contact our customer service using the contact form."
            />
          ),
          sendCouponLoading: false,
        });
      });
  };

  getActivationError = () => {
    return (
      <FormattedMessage
        id="coupon.setError"
        defaultMessage="The campaign code could not be activated. Please contact our customer service using the contact form."
      />
    );
  };

  showEmailForm = () => {
    this.setState({ showEmailFormForRetrieve: true });
  };

  getEmailForm = (onSubmit, buttonMessage) => {
    return (
      <Form
        className="CouponModal__email-form"
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          this.form.validate();
          if (this.form.valid) {
            onSubmit();
          }
        }}
      >
        <FormGroupField
          field={this.form.fields.get('email')}
          label={<FormattedMessage {...globalTranslations.emailTitle} />}
          formName={'CouponModal__email-form'}
          fieldName={'email'}
        />
        <Button
          color="primary"
          block
          disabled={this.form.valid === false || this.state.sendCouponLoading}
        >
          {buttonMessage}
        </Button>
      </Form>
    );
  };

  getSetCouponButton() {
    return (
      <Button
        block
        color="primary"
        onClick={this.setCoupon}
        disabled={this.state.setCouponLoading}
      >
        <FormattedMessage
          id="coupon.useNow"
          defaultMessage="I want to activate my code now"
        />
      </Button>
    );
  }

  getDataView = () => {
    const { activatedCoupon } = this.props;
    if (this.state.retrievedCode) {
      return (
        <Fragment>
          <Alert color="info" className="CouponModal__retrieved-code">
            {this.state.retrievedCode}
          </Alert>
          {this.getSetCouponButton()}
        </Fragment>
      );
    } else if (activatedCoupon.code) {
      return (
        <Fragment>
          {this.getSetCouponButton()}
          <Button
            block
            color="secondary"
            onClick={() => this.changeView(VIEWS.EMAIL)}
          >
            <FormattedMessage
              id="coupon.useLater"
              defaultMessage="I want to use my code later"
            />
          </Button>
        </Fragment>
      );
    } else if (activatedCoupon.activation_id) {
      const message = (
        <FormattedMessage
          id="coupon.fetchDiscountCode"
          defaultMessage="Fetch discount code"
        />
      );
      return this.state.showEmailFormForRetrieve ? (
        this.getEmailForm(this.retrieveCoupon, message)
      ) : (
        <Fragment>
          <div className="CouponModal__retrieve-privacy-statement">
            <div>
              <FormattedMessage
                id="coupon.emailSubscribeNotice"
                defaultMessage="By giving us your email address you subscribe to our newsletter."
              />{' '}
              <PrivacyStatementLink />.
            </div>
          </div>
          <Button block color="primary" onClick={this.showEmailForm}>
            {message}
          </Button>
        </Fragment>
      );
    } else {
      return null;
    }
  };

  getEmailView = () => {
    return (
      <Fragment>
        {this.getEmailForm(
          this.sendCoupon,
          <FormattedMessage {...globalTranslations.sendTitle} />
        )}
        <Button
          block
          color="secondary"
          onClick={() => this.changeView(VIEWS.DATA)}
        >
          <FormattedMessage
            id="coupon.showInformation"
            defaultMessage="Show coupon information"
          />
        </Button>
      </Fragment>
    );
  };

  render() {
    const { accountStore, activatedCoupon, toggle, isOpen } = this.props;
    const { view, successMessage, errorMessage } = this.state;

    return (
      <Modal
        className="CouponModal"
        size="lg"
        toggle={toggle}
        isOpen={isOpen}
        backdrop="static"
      >
        <ModalHeader toggle={toggle} />
        <ModalBody>
          {successMessage ? (
            <Alert color="success">{successMessage}</Alert>
          ) : (
            <Fragment>
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              <div
                dangerouslySetInnerHTML={{
                  __html: activatedCoupon.html,
                }}
                className={classNames(
                  'CouponModal__contents',
                  `CouponModal__contents--${view}`
                )}
              />
              {!accountStore.loggedIn && (
                <Fragment>
                  {view === VIEWS.DATA
                    ? this.getDataView()
                    : this.getEmailView()}
                </Fragment>
              )}
            </Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            <FormattedMessage {...globalTranslations.closeTitle} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

CouponModal.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  couponStore: modelOf(CouponStore).isRequired,
  activatedCoupon: PropTypes.shape({
    coupon_id: PropTypes.number.isRequired,
    html: PropTypes.string.isRequired,
    code: PropTypes.string,
    activation_id: PropTypes.number,
  }),
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default inject('accountStore', 'couponStore')(CouponModal);
