import React, { Component, Fragment } from 'react';
import {
  Alert,
  Button,
  Col,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { observe } from 'mobx';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import AccountStore from '../../../store/AccountStore';
import { modelOf } from '../../../prop-types';
import LoginForm from '../LoginForm';
import RegisterForm from '../RegisterForm';
import ResetPasswordForm from '../ResetPasswordForm';
import AccountModalTab from '../../../types/AccountModalTab';
import LanguageStore from '../../../store/LanguageStore';
import globalTranslations from '../../../i18n/globalTranslations';
import CouponStore from '../../../store/CouponStore';
import CouponText from '../../coupon/CouponText';
import CouponExpiry from '../../coupon/CouponExpiry';
import RequestState from '../../../types/RequestState';
import ErrorHandler from '../../loader/ErrorHandler';
import { createErrorModel } from '../../../util/error';
import QueryParamRedirect from '../../route/QueryParamRedirect';
import { parse, stringify } from '../../../util/queryString';
import ContentForState from '../../loader/ContentForState';
import ConfigStore from '../../../store/ConfigStore';
import InfoPageStore from '../../../store/InfoPageStore';
import Paths from '../../../types/Paths';
import RouteService from '../../../services/RouteService';
import {
  getStorageAndParse,
  stringifyAndSetStorage,
} from '../../../util/storage';
import { storageKeys } from '../../../util/constants';
import AccountModalClientDropdown from '../AccountModalClientDropdown';
import Analytics from '../../../analytics/Analytics';
import LoginStatus from '../../../types/account/LoginStatus';

const messages = defineMessages({
  'account.loginStatus.ACCOUNT_DISABLED': {
    id: 'account.loginStatus.ACCOUNT_DISABLED',
    defaultMessage: 'This user account has been disabled.',
  },
  'account.loginStatus.SHOP_LOGIN_DISABLED': {
    id: 'account.loginStatus.SHOP_LOGIN_DISABLED',
    defaultMessage: 'Logging in is not allowed at this time.',
  },
  // This is likely not ever used, but it is defined here to cover every possible LoginStatus value.
  'account.loginStatus.SUCCESS': {
    id: 'account.loginStatus.SUCCESS',
    defaultMessage: 'Logged in.',
  },
  'account.loginStatus.TOO_MANY_ATTEMPTS': {
    id: 'account.loginStatus.TOO_MANY_ATTEMPTS',
    defaultMessage: 'Too many attempts. Please wait a while and try again.',
  },
  'account.loginStatus.USER_ALREADY_LOGGED_IN': {
    id: 'account.loginStatus.USER_ALREADY_LOGGED_IN',
    defaultMessage: 'You are already logged in!',
  },
  'account.loginStatus.USERNAME_OR_PASSWORD_WRONG': {
    id: 'account.loginStatus.USERNAME_OR_PASSWORD_WRONG',
    defaultMessage: 'Invalid username or password!',
  },
});

@observer
export class AccountModal extends Component {
  tabs = [
    {
      id: AccountModalTab.LOGIN,
      text: <FormattedMessage {...globalTranslations.logInSentence} />,
    },
    {
      id: AccountModalTab.REGISTER,
      text: <FormattedMessage {...globalTranslations.accountCreateSentence} />,
    },
    {
      id: AccountModalTab.CHANGE_CLIENT,
      text: (
        <FormattedMessage {...globalTranslations.accountChangeClientSentence} />
      ),
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      loginStatus: null,
      loginLoading: false,
      registerLoading: false,
      clientLoadingState: RequestState.NONE,
      resetPasswordError: null,
      resetPasswordRequestState: RequestState.NONE,
      selectedClient: null,
      clientSearch: '',
    };

    const { accountStore, configStore, useAsModal } = props;

    const infoPageId = useAsModal
      ? configStore.infoPages.customerBenefits
      : configStore.infoPages.entryLogin;

    if (
      (accountStore.accountModal.isOpen && infoPageId) ||
      (!useAsModal && infoPageId)
    ) {
      this.maybeLoadBenefitsInfoPage();
    }

    observe(accountStore.accountModal, (change) => {
      // Reset state when reopening the modal
      if (
        change.name === 'isOpen' &&
        !change.oldValue.value &&
        change.newValue.value
      ) {
        this.setState(() => ({
          loginStatus: null,
          loginLoading: false,
          registerLoading: false,
          clientLoadingState: RequestState.NONE,
          resetPasswordError: null,
          resetPasswordRequestState: RequestState.NONE,
          selectedClient: null,
          clientSearch: '',
        }));
      }
    });
  }

  componentDidUpdate() {
    const { accountStore, configStore, infoPageStore, useAsModal } = this.props;
    const pageId = useAsModal
      ? configStore.infoPages.customerBenefits
      : configStore.infoPages.entryLogin;

    if (
      ((accountStore.accountModal.isOpen && pageId) ||
        (!useAsModal && pageId)) &&
      infoPageStore.pageStates.get(pageId) !== RequestState.LOADING &&
      infoPageStore.pageStates.get(pageId) !== RequestState.LOADED &&
      infoPageStore.pageStates.get(pageId) !== RequestState.ERROR
    ) {
      this.maybeLoadBenefitsInfoPage();
    }
  }

  maybeLoadBenefitsInfoPage = () => {
    const { infoPageStore, configStore, accountStore, useAsModal } = this.props;
    const pageId = useAsModal
      ? configStore.infoPages.customerBenefits
      : configStore.infoPages.entryLogin;
    if (
      ((accountStore.accountModal.isOpen &&
        configStore.registration.enabled &&
        pageId) ||
        (!useAsModal && pageId)) &&
      pageId.length > 0 &&
      infoPageStore.pageStates.get(pageId) !== RequestState.LOADED &&
      infoPageStore.pageStates.get(pageId) !== RequestState.ERROR
    ) {
      infoPageStore.loadPage(pageId).catch((e) => {
        if (e.responce && e.responce.status !== 404) {
          console.error(e);
        }
      });
    }
  };

  parseSearchText = (text = '') => {
    return text.trim();
  };

  loadClients = () => {
    const { accountStore, configStore } = this.props;
    const searchText = this.parseSearchText(this.state.clientSearch);
    let minCharAmount = 0;

    if (configStore.account.allowAllClients) {
      minCharAmount = 2;
    }

    if (
      accountStore.accountModal.isOpen &&
      accountStore.isClientLoginEnabled &&
      this.state.clientLoadingState !== RequestState.LOADING &&
      searchText.length >= minCharAmount
    ) {
      this.setState(() => ({
        clientLoadingState: RequestState.LOADING,
      }));
      accountStore
        .loadClients(searchText)
        .then(() => {
          this.setState(() => ({
            clientLoadingState: RequestState.LOADED,
          }));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  setClientSearch = (text) => {
    this.setState({
      clientSearch: this.parseSearchText(text.toLowerCase()),
    });
  };

  getInfoPage = () => {
    const { configStore, infoPageStore, useAsModal } = this.props;
    const infoPageId = useAsModal
      ? configStore.infoPages.customerBenefits
      : configStore.infoPages.entryLogin;

    return infoPageId &&
      infoPageStore.pageStates.get(infoPageId) === RequestState.LOADED
      ? infoPageStore.pages.get(infoPageId)
      : null;
  };

  getBenefitsState = () => {
    const { infoPageStore, configStore, useAsModal } = this.props;
    const infoPageId = useAsModal
      ? configStore.infoPages.customerBenefits
      : configStore.infoPages.entryLogin;

    if (!infoPageId) {
      return RequestState.LOADED;
    }
    if (infoPageStore.pageStates.get(infoPageId) !== RequestState.LOADING) {
      return RequestState.LOADED;
    }
    return infoPageStore.pageStates.get(infoPageId);
  };

  getProductListProducts = (products) => {
    if (!products) {
      return;
    }

    const productListProducts = [];
    products.forEach((product) => {
      productListProducts.push({
        extendedId: product.id,
        quantity: product.quantity,
      });
    });
    return productListProducts;
  };

  loginStatusOk = (status) => {
    return (
      status === LoginStatus.SUCCESS ||
      status === LoginStatus.USER_ALREADY_LOGGED_IN
    );
  };

  login = (values) => {
    const { accountStore, configStore } = this.props;

    this.setState({
      loginLoading: true,
    });
    const storageProductListProducts = getStorageAndParse(storageKeys.wishlist);
    const productListProducts = this.getProductListProducts(
      storageProductListProducts
    );
    accountStore
      .login(values.username, values.password, productListProducts)
      .then((response) => {
        this.processLogin(response);
        configStore.analytics.ga4.enabled && this.sendAnalyticsLoginEvent();
      });
  };

  sendAnalyticsLoginEvent = () => {
    const { analytics } = this.props;
    analytics.login('Webstore');
  };

  getPostLoginRedirectPath = () => {
    const { accountStore, routeService } = this.props;
    return routeService.getPath(accountStore.account.post_login_redirect_path);
  };

  ifPostLoginRedirectPath = () => {
    const { accountStore } = this.props;
    return (
      accountStore.account.post_login_redirect_path &&
      accountStore.account.post_login_redirect_path.length > 0
    );
  };

  resetPassword = ({ email }) => {
    this.setState(
      () => ({
        resetPasswordRequestState: RequestState.LOADING,
        resetPasswordError: null,
      }),
      () => {
        this.props.accountStore
          .resetPassword(email)
          .then(() => {
            this.setState(() => ({
              resetPasswordRequestState: RequestState.LOADED,
            }));
          })
          .catch((e) => {
            this.setState(() => ({
              resetPasswordRequestState: RequestState.ERROR,
              resetPasswordError: e,
            }));
          });
      }
    );
  };

  getNav = () => {
    const { accountStore, configStore } = this.props;

    const tabs = this.tabs
      .filter((tab) => {
        if (tab.id === AccountModalTab.LOGIN && accountStore.loggedIn) {
          return false;
        }
        if (
          tab.id === AccountModalTab.REGISTER &&
          (!configStore.registration.enabled || accountStore.loggedIn)
        ) {
          return false;
        }
        if (
          tab.id === AccountModalTab.CHANGE_CLIENT &&
          (!accountStore.loggedIn || !accountStore.isClientLoginEnabled)
        ) {
          return false;
        }

        return true;
      })
      .map((tab) => (
        <NavItem key={tab.id}>
          <NavLink
            href="#"
            active={tab.id === accountStore.accountModal.tab}
            onClick={() => accountStore.accountModal.setTab(tab.id)}
          >
            {tab.text}
          </NavLink>
        </NavItem>
      ));
    return (
      <Nav tabs className="AccountModal__tabs">
        {tabs}
      </Nav>
    );
  };

  getCoupon = () => {
    const { couponStore } = this.props;

    const coupon = couponStore.loginCoupon || couponStore.registrationCoupon;
    if (coupon) {
      return (
        <div className="AccountModal__coupon">
          <h4>{coupon.name}</h4>
          <CouponText coupon={coupon} />
          {coupon.end_date && (
            <div>
              (<CouponExpiry date={coupon.end_date} />)
            </div>
          )}
        </div>
      );
    }
  };

  getDefaultBenefits = () => {
    return (
      <>
        <h4>
          <FormattedMessage
            id="account.registrationBenefitsHeader"
            defaultMessage="Registration benefits"
          />
        </h4>
        <ul className="AccountModal__registration-benefits">
          <li>
            <FormattedMessage
              id="account.registrationBenefitsNewsletterItem"
              defaultMessage="Newsletter - you'll be informed of special offers and campaigns available through our store."
            />
          </li>
          <li>
            <FormattedMessage
              id="account.registrationBenefitsFasterCheckoutItem"
              defaultMessage="Easy and quick checkout - your personal details will be filled in automatically."
            />
          </li>
          <li>
            <FormattedMessage
              id="account.registrationBenefitsOrderHistoryItem"
              defaultMessage="Order history - you'll be able to see the orders you've previously done through our store."
            />
          </li>
          <li>
            <FormattedMessage
              id="account.registrationBenefitsPersistentCartItem"
              defaultMessage="Persistent cart - the items you add to your cart will stay there until you remove them or confirm the order."
            />
          </li>
          <li>
            <FormattedMessage
              id="account.registrationBenefitsWishlistItem"
              defaultMessage="You'll be able to create a wishlist you can then share with your friends."
            />
          </li>
        </ul>
      </>
    );
  };

  getBenefits = () => {
    const { useAsModal } = this.props;
    const infoPage = this.getInfoPage();
    return infoPage && infoPage.content ? (
      <div
        className="AccountModal__body-content"
        dangerouslySetInnerHTML={{
          __html: infoPage.content,
        }}
      />
    ) : (
      useAsModal && this.getDefaultBenefits()
    );
  };

  getClients = () => {
    const { accountStore } = this.props;
    const { clientSearch } = this.state;
    const filteredList = accountStore.account.clients;

    return filteredList.filter((client) => {
      return (
        client.firstname.toLowerCase().includes(clientSearch) ||
        client.lastname.toLowerCase().includes(clientSearch) ||
        client.company.toLowerCase().includes(clientSearch) ||
        client.id.toString().includes(clientSearch)
      );
    });
  };

  onClientChange = (client) => {
    this.setState(() => ({
      selectedClient: client,
    }));
  };

  processLogin = (response) => {
    const { accountStore, configStore, languageStore, routeService } =
      this.props;
    const { status } = response;
    const { account, accountModal, isBalanceUser, default_language_code } =
      accountStore;

    if (!this.loginStatusOk(status)) {
      this.setState({
        loginStatus: LoginStatus[status],
        loginLoading: false,
        clientLoadingState: RequestState.NONE,
      });
    } else {
      stringifyAndSetStorage(storageKeys.wishlist, []);
      languageStore.getRedirectedUrl(default_language_code).then((url) => {
        accountModal.toggle();
        let browserUrl = url;

        if (this.ifPostLoginRedirectPath()) {
          browserUrl = this.getPostLoginRedirectPath();
        }

        if (account.redirect_to_customers_prices) {
          browserUrl = routeService.getPath(Paths.CustomerProducts);
        }

        if (configStore.account.isMoneyTransfer) {
          // money transfer application always uses myAccount pages
          browserUrl = isBalanceUser
            ? routeService.getPath(Paths.MyAccountBalanceSubUser)
            : routeService.getPath(Paths.MyAccountBalanceUser);
        }

        if (browserUrl.hash) {
          window.location.reload();
        } else {
          window.location.assign(browserUrl);
        }
      });
    }
  };

  clientLogin = () => {
    const { accountStore } = this.props;

    this.setState({
      loginLoading: true,
    });

    accountStore.loginClient(this.state.selectedClient.id).then((response) => {
      this.processLogin(response);
    });
  };

  getBody = () => {
    const { accountStore, configStore, useAsModal, intl } = this.props;
    const {
      resetPasswordRequestState,
      resetPasswordError,
      loginStatus,
      selectedClient,
      clientSearch,
      clientLoadingState,
      loginLoading,
    } = this.state;

    const resetPassword =
      resetPasswordRequestState === RequestState.LOADED ? (
        <Alert color="success">
          <FormattedMessage
            id="account.resetPasswordSuccessful"
            defaultMessage="A password reset email has been sent to the provided address successfully. Remember to check your spam folder as well or make sure that the email address is correct if the message doesn't seem to have arrived."
          />
        </Alert>
      ) : (
        <ResetPasswordForm
          onSubmit={this.resetPassword}
          disableSubmit={resetPasswordRequestState === RequestState.LOADING}
        />
      );

    const resetPasswordContent = configStore.account.enableMyAccount ? (
      <>
        <Button
          color="link"
          onClick={accountStore.accountModal.toggleResetPasswordVisible}
        >
          <FormattedMessage
            id="account.forgotPasswordLink"
            defaultMessage="Forgot your password? Click here."
          />
        </Button>
        <Collapse isOpen={accountStore.accountModal.resetPasswordVisible}>
          <p>
            <FormattedMessage
              id="account.forgotPasswordInstructions"
              defaultMessage="If you have forgotten your password, please enter the email address of your customer account below in order to reset your password."
            />
          </p>
          {resetPasswordError && (
            <ErrorHandler error={createErrorModel(resetPasswordError)} />
          )}
          {resetPassword}
        </Collapse>
      </>
    ) : null;
    let content;
    switch (accountStore.accountModal.tab) {
      case AccountModalTab.REGISTER:
        content = (
          <Row>
            <Col xs={12}>{this.getCoupon()}</Col>
            <Col xs={6}>
              <RegisterForm />
            </Col>
            <Col xs={6}>
              <ContentForState
                state={this.getBenefitsState()}
                forLoaded={() => {
                  return this.getBenefits();
                }}
              />
            </Col>
          </Row>
        );
        break;
      case AccountModalTab.CHANGE_CLIENT:
        content = (
          <Row>
            <Col xs={12}>
              {loginStatus && !this.loginStatusOk(loginStatus) && (
                <Alert color="danger">
                  {intl.formatMessage(
                    messages[`account.loginStatus.${loginStatus}`]
                  )}
                </Alert>
              )}
              <AccountModalClientDropdown
                client={selectedClient}
                clients={this.getClients()}
                clientFilter={clientSearch}
                setClientFilter={this.setClientSearch}
                searchClients={this.loadClients}
                onSelect={this.onClientChange}
                loading={clientLoadingState}
              />
              <Button
                color="primary"
                onClick={this.clientLogin}
                disabled={!selectedClient}
              >
                <FormattedMessage
                  {...globalTranslations.accountChangeClientSentence}
                />
              </Button>
            </Col>
          </Row>
        );
        break;
      case AccountModalTab.LOGIN:
      default:
        content = (
          <Row>
            <Col xs={12}>
              {this.getCoupon()}
              {loginStatus && !this.loginStatusOk(loginStatus) && (
                <Alert color="danger">
                  {intl.formatMessage(
                    messages[`account.loginStatus.${loginStatus}`]
                  )}
                </Alert>
              )}
              <LoginForm onSubmit={this.login} disableSubmit={loginLoading} />
              {resetPasswordContent}
              {!useAsModal && (
                <div className="AccountModal__benefits-content">
                  <ContentForState
                    state={this.getBenefitsState()}
                    forLoaded={() => {
                      return this.getBenefits();
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
        );
    }
    return <div className="AccountModal__tab-content">{content}</div>;
  };

  getPathForRedirect = (type) => {
    const { location } = this.props;

    let params = parse(location.search);
    delete params[type];

    params = stringify(params);
    if (params.length > 0) {
      params = '?' + params;
    }

    return location.pathname + params + location.hash;
  };

  openModalOnRedirect = (type) => {
    const { accountModal } = this.props.accountStore;

    // Trigger the side effect (actually opening the modal)
    // outside the render cycle.
    setImmediate(() => {
      switch (type) {
        case 'openRegistrationModal':
          accountModal.setTab(AccountModalTab.REGISTER);
          break;
        case 'openPasswordForgottenModal':
          accountModal.setTab(AccountModalTab.LOGIN);
          if (!accountModal.resetPasswordVisible) {
            accountModal.toggleResetPasswordVisible();
          }
          break;
        case 'openLoginModal':
        default:
          accountModal.setTab(AccountModalTab.LOGIN);
          break;
      }
      if (!accountModal.isOpen) {
        accountModal.toggle();
      }
    });
  };

  render() {
    const { accountStore, useAsModal } = this.props;

    /* Reactstrap doesn't support document at the moment.
     *  https://github.com/reactstrap/reactstrap/issues/1071
     *  Once the bug is fixed modal can be tested in SSR env.
     * */
    if (window.isSSR && useAsModal) {
      return null;
    }

    return (
      <>
        {useAsModal && (
          <Modal
            toggle={accountStore.accountModal.toggle}
            className="AccountModal"
            size="lg"
            isOpen={accountStore.accountModal.isOpen}
          >
            <ModalHeader
              className="AccountModal__header"
              toggle={accountStore.accountModal.toggle}
            />
            <ModalBody>
              {this.getNav()}
              {this.getBody()}
            </ModalBody>
          </Modal>
        )}
        {!useAsModal && this.getBody()}
        {[
          'openRegistrationModal',
          'openLoginModal',
          'openPasswordForgottenModal',
        ].map((param) => (
          <QueryParamRedirect
            key={param}
            paramName={param}
            to={() => this.getPathForRedirect(param)}
            onRedirect={() => this.openModalOnRedirect(param)}
          />
        ))}
      </>
    );
  }
}

AccountModal.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  couponStore: modelOf(CouponStore).isRequired,
  infoPageStore: modelOf(InfoPageStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  location: RouterPropTypes.location.isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  useAsModal: PropTypes.bool,
};

AccountModal.defaultProps = {
  useAsModal: true,
};

export default withRouter(
  injectIntl(
    inject(
      'accountStore',
      'configStore',
      'couponStore',
      'infoPageStore',
      'languageStore',
      'analytics',
      'routeService'
    )(AccountModal)
  )
);
