import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';

import AccountModalLink from '../AccountModalLink';
import AccountModalTab from '../../../types/AccountModalTab';
import Icon from '../../common/Icon';
import globalTranslations from '../../../i18n/globalTranslations';

@observer
class LoginLink extends Component {
  render() {
    return (
      <AccountModalLink className="LoginLink" tab={AccountModalTab.LOGIN}>
        <Icon name="unlock-alt" />
        <FormattedMessage {...globalTranslations.logInSentence} />
      </AccountModalLink>
    );
  }
}

export default LoginLink;
