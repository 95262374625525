import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cookies from 'js-cookie';
import PropTypes from 'prop-types';

import ConfigStore from '../../../store/ConfigStore';
import { inject, observer } from 'mobx-react';
import { modelOf } from '../../../prop-types';
import RouteService from '../../../services/RouteService';
import globalTranslations from '../../../i18n/globalTranslations';

const COOKIE_KEY = 'nhcookielaw';

@observer
export class CookieNotice extends Component {
  state = {
    visible: !cookies.get(COOKIE_KEY)
  };

  accept = () => {
    cookies.set(COOKIE_KEY, Math.floor(Date.now() / 1000), {
      expires: 5 * 365
    });
    this.setState({
      visible: false
    });
  };

  render() {
    const { configStore, routeService } = this.props;

    if (
      window.isSSR ||
      !configStore.cookieNotice.enabled ||
      !this.state.visible
    ) {
      return null;
    }

    return (
      <div className="CookieNotice" mode="bottom">
        <div className="CookieNotice__container">
          <div className="CookieNotice__content">
            <FormattedMessage
              id="cookieNotice.content"
              defaultMessage="In order to serve our customers as well as possible, we use cookies on our site. By continuing, you agree to use the cookies."
            />{' '}
            {configStore.cookieNotice.infoPageId && (
              <Link
                to={routeService.getPath(
                  `/i/-/${configStore.cookieNotice.infoPageId}`
                )}
              >
                <FormattedMessage
                  id="cookieNotice.readMore"
                  defaultMessage="Read more about cookies"
                />
              </Link>
            )}
          </div>
          <div className="CookieNotice__button-wrapper">
            <Button
              className="CookieNotice__button"
              size="lg"
              onClick={this.accept}
            >
              <FormattedMessage {...globalTranslations.ok} />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

CookieNotice.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired
};

export default inject('configStore', 'routeService')(CookieNotice);
