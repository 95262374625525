import LinkType from '../../types/LinkType';
import {
  domainMatchesUrl,
  stripHostIfMatches,
  transformExternalLink,
  transformInternalLink,
} from '../../util/url';

const useMaybeExternalLink = ({ path, type, host }) => {
  if (!type) {
    return [path, '', ''];
  }

  if (type && type === LinkType.INTERNAL_LINK) {
    return [transformInternalLink(path), '', ''];
  }

  if (type && type === LinkType.PAGE_LINK) {
    return [stripHostIfMatches(path, host), '', ''];
  }

  if (
    type &&
    type === LinkType.EXTERNAL_LINK &&
    !!domainMatchesUrl(host, path)
  ) {
    return [transformExternalLink(path), '_self', ''];
  }

  if (
    type &&
    type === LinkType.EXTERNAL_LINK &&
    !!!domainMatchesUrl(host, path)
  ) {
    return [path, '_blank', 'noopener'];
  }
};

export default useMaybeExternalLink;
