import { types } from 'mobx-state-tree';

import Order from './Order';

const OrdersPaginator = types.model('OrdersPaginator', {
  current_page: types.number,
  data: types.optional(types.array(types.reference(Order)), []),
  last_page: types.number,
  total: types.number
});

export default OrdersPaginator;
