import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BannerSlider from '../BannerSlider/BannerSliderSlides';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import BannerStyle from '../../../types/banner/BannerStyle';
import UIStore from '../../../store/UIStore';
import AdZones from '../../../types/AdZones';

const ASPECT_RATIO = 2.85;

const MainBanner = ({
  configStore,
  uiStore,
  className,
  searchParams,
  overrideSettings,
  shouldHaveMobile,
}) => {
  const getMainBannerSettings = () => {
    const style = configStore.banner.mainBanner.style;
    let styleSettings = {};

    switch (style) {
      case BannerStyle.INFINITE:
        styleSettings = {
          infinite: true,
        };
        break;
      case BannerStyle.CENTERED:
        styleSettings = {
          centerMode: true,
        };
        break;
      case BannerStyle.BOTH:
        styleSettings = {
          infinite: true,
          centerMode: true,
        };
        break;
      default:
        styleSettings = {};
        break;
    }

    return styleSettings;
  };

  const bannerSettings = {
    ...overrideSettings,
    ...getMainBannerSettings(),
  };

  return (
    <BannerSlider
      adZone={AdZones.MAIN_BANNER}
      searchParams={{
        ...searchParams,
      }}
      className={classNames('MainBanner', className)}
      aspectRatio={ASPECT_RATIO}
      maximumCrossAxisSize={
        uiStore.isMobile
          ? shouldHaveMobile
            ? window.innerWidth
            : window.innerWidth / ASPECT_RATIO
          : configStore.banner.mainBanner.height
      }
      overrideSettings={bannerSettings}
    />
  );
};

MainBanner.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  className: PropTypes.string,
  overrideSettings: PropTypes.object,
  searchParams: PropTypes.object,
  shouldHaveMobile: PropTypes.bool,
};

MainBanner.defaultProps = {
  shouldHaveMobile: false,
};

export default inject((stores) => ({
  configStore: stores.configStore,
  fullWidth: stores.configStore.banner.mainBanner.fullWidth,
  uiStore: stores.uiStore,
}))(observer(MainBanner));
