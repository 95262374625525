import React from 'react';
import { inject, observer } from 'mobx-react';

import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import useTopHeadScript from '../../../hooks/dom/useTopHeadScript';

const ServiceFormWidget = ({ configStore }) => {
  if (!configStore.integrations.serviceForm.enabled) {
    return null;
  }

  // Bail if we are in SSR.
  if (typeof document === 'undefined') {
    return null;
  }

  useTopHeadScript({
    url: 'https://cdn.serviceform.com/serviceform-tools/privacy/sf-privacy-partner.js?v=nethit',
  });

  return <noscript>You need to enable JavaScript</noscript>;
};

ServiceFormWidget.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
};

export default inject('configStore')(observer(ServiceFormWidget));
