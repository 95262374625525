import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import LanguageDependantRoutes from '../LanguageDependantRoutes';
import { modelOf } from '../../../prop-types';
import LanguageStore from '../../../store/LanguageStore';
import Paths from '../../../types/Paths';
import useRoutes from '../../../hooks/routes/useRoutes';
import ConfigStore from '../../../store/ConfigStore';
import RouteService from '../../../services/RouteService';
import SectionStore from '../../../store/SectionStore';
import HomePage from '../../../pages/HomePage';
import NotFoundPage from '../../../pages/NotFoundPage';
import SectionAwareRoute from '../SectionAwareRoute';

const AppRoutes = ({
  configStore,
  sectionStore,
  languageStore,
  routeService,
}) => {
  const location = useLocation();

  const getDefaultLanguageDependantRedirects = () => {
    const overrideComponent = (
      <Redirect
        to={`/${languageStore.activeLocale}${location.pathname}${location.search}${location.hash}`}
      />
    );

    return useRoutes({
      configStore,
      sections: sectionStore.sections,
      match: '',
      overrideComponent,
    });
  };

  const getSingleLanguageRoutes = () =>
    useRoutes({
      configStore,
      sections: sectionStore.sections,
      match: '',
    });

  const routes =
    languageStore.languages.length > 1
      ? [
          ...languageStore.languages.map((language) => (
            <Route
              key={language.code}
              path={`/${language.code}`}
              children={() => <LanguageDependantRoutes />}
            />
          )),
          <Route
            key={Paths.FrontPage}
            exact
            path={Paths.FrontPage}
            render={() => (
              <Redirect to={routeService.getPath(Paths.FrontPage)} />
            )}
          />,
          ...getDefaultLanguageDependantRedirects(),
        ]
      : [
          ...getSingleLanguageRoutes(),
          <Route
            key={Paths.FrontPage}
            exact
            path={Paths.FrontPage}
            children={() => <SectionAwareRoute component={<HomePage />} />}
          />,
        ];

  return (
    <Switch>
      <Route
        exact
        strict
        path="/:url*"
        render={(routerProps) => (
          <Redirect
            to={`${routerProps.location.pathname}/${routerProps.location.search}${routerProps.location.hash}`}
          />
        )}
      />
      {routes}
      <Route key={Paths.NotFoundPage} path={Paths.NotFoundPage}>
        <NotFoundPage />
      </Route>
      <Route key={Paths.NotFoundPage} path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

AppRoutes.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  sectionStore: modelOf(SectionStore).isRequired,
  languageStore: modelOf(LanguageStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
};

export default inject(
  'configStore',
  'sectionStore',
  'languageStore',
  'routeService'
)(observer(AppRoutes));
