import { types } from 'mobx-state-tree';
import { OrderTotalType } from '../types/OrderTotalClass';

const OrderTotalValue = types.model('OrderTotalValue', {
  class: OrderTotalType,
  name: types.string,
  text: types.optional(types.string, ''),
  value: types.number,
});

export default OrderTotalValue;
