import {
  types,
  getEnv,
  flow,
  resolveIdentifier,
  getRoot,
} from 'mobx-state-tree';

import Currency from '../models/Currency';
import StatefulStore from '../models/StatefulStore';

const DEFAULT_PRECISION = 2;

const CurrencyStore = StatefulStore.named('CurrencyStore')
  .props({
    currencies: types.optional(types.array(Currency), []),
    currencyCode: types.optional(types.string, ''),
  })
  .actions((self) => {
    const setup = (currencies, currencyCode) => {
      self.currencies = currencies;
      self.currencyCode = currencyCode;

      self.setLoading(false);
    };

    const loadCurrencies = flow(function* loadCurrencies() {
      self.setLoading(true);

      try {
        self.currencies = yield getEnv(self).apiWrapper.request(`currencies`);
      } catch (e) {
        self.setError(e);
        throw e;
      }

      self.setLoading(false);
    });

    return {
      setup,
      loadCurrencies,
    };
  })
  .views((self) => {
    return {
      get activeCurrency() {
        return self.currencyCode ? self.findCurrency(self.currencyCode) : null;
      },
      get activeCurrencyPrecision() {
        return self.activeCurrency
          ? self.activeCurrency.precision
          : DEFAULT_PRECISION;
      },
      findCurrency(currencyCode) {
        return resolveIdentifier(
          Currency,
          getRoot(self.currencies),
          currencyCode
        );
      },
    };
  });

export default CurrencyStore;
