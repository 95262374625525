import { types } from 'mobx-state-tree';

import { OrderStatusType } from '../types/OrderStatusClass';

const OrderStatusHistoryEntry = types.model('OrderStatusHistoryEntry', {
  date: types.string,
  status: OrderStatusType,
  message: types.string
});

export default OrderStatusHistoryEntry;
