import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import AccountModalLink from '../AccountModalLink';
import AccountModalTab from '../../../types/AccountModalTab';
import globalTranslations from '../../../i18n/globalTranslations';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class RegisterLink extends Component {
  render() {
    const { prefix, configStore } = this.props;

    if (!configStore.registration.enabled) {
      return null;
    }

    return (
      <Fragment>
        {prefix || null}
        <AccountModalLink
          className="RegisterLink"
          tab={AccountModalTab.REGISTER}
        >
          <FormattedMessage {...globalTranslations.accountCreateSentence} />
        </AccountModalLink>
      </Fragment>
    );
  }
}

RegisterLink.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  prefix: PropTypes.string
};

export default inject('configStore')(RegisterLink);
