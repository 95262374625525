import { types, getEnv, flow, getRoot } from 'mobx-state-tree';
import { keyBy } from 'lodash';

import Manufacturer from '../models/Manufacturer';
import StatefulStore from '../models/StatefulStore';
import RequestState, { RequestStateType } from '../types/RequestState';

const ManufacturerStore = StatefulStore.named('ManufacturerStore')
  .props({
    manufacturers: types.optional(types.map(Manufacturer), {}),
    manufacturerContentStates: types.optional(types.map(RequestStateType), {}),
    loadedWithContent: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get manufacturersArray() {
        return Array.from(self.manufacturers, ([key, value]) => value).sort(
          (a, b) => a.name.localeCompare(b.name)
        );
      },
      get manufacturersWithProducts() {
        return self.manufacturersArray.filter(
          (manufacturer) => manufacturer.has_products
        );
      },
      get hasManufacturers() {
        return self.manufacturersArray.length > 0;
      },
    };
  })
  .actions((self) => {
    const ifShoppingCenter = () =>
      getRoot(self).configStore
        ? getRoot(self).configStore.siteConfig.isShoppingCenter
        : false;

    const baseApi = (endpoint) =>
      ifShoppingCenter() ? `shopping-center/${endpoint}` : `${endpoint}`;

    return {
      setup: (manufacturers) => {
        self.manufacturers = keyBy(manufacturers, 'id');
        self.setLoading(false);
      },
      loadManufacturer: flow(function* loadManufacturer(id) {
        self.manufacturerContentStates.set(id, RequestState.LOADING);
        try {
          const manufacturer = yield getEnv(self).apiWrapper.request(
            `${baseApi('manufacturers')}/${id}`
          );
          self.manufacturers.set(manufacturer.id, manufacturer);
          self.manufacturerContentStates.set(id, RequestState.LOADED);
        } catch (e) {
          self.setError(e);
          self.manufacturerContentStates.set(id, RequestState.ERROR);
          throw e;
        }
      }),
      loadManufacturers: flow(function* loadManufacturers(
        excludeContent = true
      ) {
        self.setLoading(true);
        try {
          const manufacturers = yield getEnv(self).apiWrapper.request(
            `${baseApi('manufacturers')}?excludeContent=${Number(
              excludeContent
            )}`
          );
          self.manufacturers.merge(keyBy(manufacturers, 'id'));

          if (excludeContent === false && Array.isArray(manufacturers)) {
            self.loadedWithContent = true;
            manufacturers.forEach((manufacturer) => {
              self.manufacturerContentStates.set(
                manufacturer.id,
                RequestState.LOADED
              );
            });
          }
        } catch (e) {
          self.setError(e);
          throw e;
        }

        self.setLoading(false);
      }),
    };
  });

export default ManufacturerStore;
