import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { injectIntl } from 'react-intl';

import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';
import AccountModal from '../AccountModal';
import SEOHomePage from '../../head/SEOHomePage';
import PageTitle from '../../common/PageTitle';
import globalTranslations from '../../../i18n/globalTranslations';
import ConfigStore from '../../../store/ConfigStore';

@observer
export class LoginPageContent extends Component {
  render() {
    const { configStore, intl } = this.props;

    const title =
      configStore.configurationTexts.CONF_SEO_TITLE ||
      intl.formatMessage(globalTranslations.homeTitle);

    return (
      <div className="LoginPageContent">
        <SEOHomePage />
        <PageTitle itemProp={title}>{title}</PageTitle>
        <AccountModal key={'LoginPageContent'} useAsModal={false} />
      </div>
    );
  }
}

LoginPageContent.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired
};

export default injectIntl(
  inject('accountStore', 'configStore')(LoginPageContent)
);
