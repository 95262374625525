import { DateTime } from 'luxon';

import { useCallback } from 'react';
import ProductAvailabilityType from '../../types/ProductAvailabilityType';
import { roundWithPrecision } from '../../util/number';

const useProductSchema = ({
  accountStore,
  configStore,
  currencyStore,
  routeService,
  locationContext,
}) => {
  const getProductSchema = useCallback((product) => {
    const productSchema = {};

    const baseUrl = `${locationContext.protocol}//${locationContext.host}`;
    const productUrl = `${baseUrl}${routeService.getProductPath(product)}`;

    const getImageUrls = () => {
      const images = product.getImagesBySize('full').map((imagePathOrUrl) => {
        if (imagePathOrUrl.includes('http')) {
          return imagePathOrUrl;
        }

        return `${baseUrl}${imagePathOrUrl}`;
      });

      return images;
    };

    productSchema['@type'] = 'Product';
    productSchema.name = product.name;
    productSchema.gtin = product.ean;
    productSchema.image = getImageUrls();
    productSchema.sku = product.id;
    productSchema.description =
      product.description_short || product.seoDescription;
    productSchema.mpn = product.manufacturer_product_id;

    const shouldShowModel = () => {
      return configStore.product.showModel;
    };

    if (shouldShowModel()) {
      productSchema.model = product.model;
    }

    const getAvailability = () => {
      if (
        !product.availability_html ||
        product.availability_html.length === 0
      ) {
        return null;
      }

      if (product.availability_type === ProductAvailabilityType.ONLY_IN_SHOP) {
        return 'https://schema.org/InStoreOnly';
      } else if (
        product.free_quantity === 0 &&
        product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER
      ) {
        return 'https://schema.org/BackOrder';
      } else if (product.free_quantity > 0) {
        return 'https://schema.org/InStock';
      }
    };

    const getValidProductPriceDate = () => {
      if (!product.price_info) {
        return null;
      }

      return DateTime.fromISO(product.price_info.valid_until).toISODate();
    };

    const getProductOfferSchema = () => {
      const withTax = accountStore.showPricesWithTax;

      const baseOffer = {
        '@type': 'Offer',
        priceCurrency: currencyStore.currencyCode,
      };

      return {
        ...baseOffer,
        url: productUrl,
        price: product.price_info
          ? roundWithPrecision(product.price_info.getPrice(withTax), 2)
          : 0,
        priceValidUntil: getValidProductPriceDate(),
        availability: getAvailability(),
      };
    };

    if (product.mainCategory) {
      productSchema.category = product.mainCategory.hierarchy
        .map((category) => category.name)
        .join('>');
    }

    if (configStore.productPage.showWeight) {
      productSchema.weight = {
        '@type': 'QuantitativeValue',
        value: product.display_weight,
        unitCode: product.stock_unit,
      };
    }

    productSchema.offers = getProductOfferSchema();

    return productSchema;
  }, []);

  return [getProductSchema];
};

export default useProductSchema;
