import React, { Component } from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import RouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import Analytics from '../../../analytics/Analytics';
import TrackingStore from '../../../store/TrackingStore';
import UIStore from '../../../store/UIStore';
import ConfigStore from '../../../store/ConfigStore';
import { isCrawler } from '../../../util/browser';

class Page extends Component {
  constructor(props) {
    super(props);

    this.yotpoTimeout = null;
  }

  componentDidMount() {
    const { analytics, configStore, uiStore, name } = this.props;
    uiStore.setCurrentPage(name);
    analytics.setPage(name);
    !isCrawler() && this.sendTrackingData();

    if (configStore.integrations.yotpo.enabled && window && window.yotpo) {
      this.yotpoTimeout = setTimeout(() => {
        window.yotpo.refreshWidgets();
      }, 800);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isCrawler() &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.sendTrackingData();
    }
  }

  componentWillUnmount() {
    this.yotpoTimeout && clearTimeout(this.yotpoTimeout);
  }

  sendTrackingData = () => {
    const { trackingStore, onDefinedTrackingPage, configStore } = this.props;

    if (
      !configStore.integrations.spotmore.enabled ||
      typeof document === 'undefined' ||
      onDefinedTrackingPage
    ) {
      return null;
    }

    if (configStore.integrations.spotmore.enabled && window.crmtracker) {
      window.crmtracker.sendData();
    }

    trackingStore.setPageView({
      visitedUrl: window.location.href,
      refererUrl: document.referrer,
    });
    trackingStore.postPageView();
  };

  render() {
    const { className, children } = this.props;

    return <div className={classNames('Page', className)}>{children}</div>;
  }
}

Page.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  trackingStore: modelOf(TrackingStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  location: RouterPropTypes.location.isRequired,
  name: PropTypes.string.isRequired,
  onDefinedTrackingPage: PropTypes.string,
};

export default inject(
  'analytics',
  'uiStore',
  'trackingStore',
  'configStore'
)(withRouter(Page));
