import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import CouponModal from '../CouponModal';
import { modelOf } from '../../../prop-types';
import AccountStore from '../../../store/AccountStore';

@observer
export class ActivatedCoupons extends Component {
  removeActiveCoupon = (activatedCoupon) => {
    const { accountStore } = this.props;
    if (accountStore.session) {
      accountStore.session.removeCoupon(activatedCoupon);
    }
  };

  render() {
    const { accountStore } = this.props;

    return (
      <div className="ActivatedCoupons">
        {accountStore.session &&
          accountStore.session.activated_coupons
            .slice(0, 1)
            .map((activatedCoupon) => (
              <CouponModal
                key={activatedCoupon.code}
                toggle={() => this.removeActiveCoupon(activatedCoupon)}
                isOpen={true}
                activatedCoupon={activatedCoupon}
              />
            ))}
      </div>
    );
  }
}

ActivatedCoupons.propTypes = {
  accountStore: modelOf(AccountStore).isRequired
};

export default inject('accountStore')(ActivatedCoupons);
