import React, { Component } from 'react';
import AccountStore from '../../../store/AccountStore';
import { modelOf } from '../../../prop-types';
import { inject, observer } from 'mobx-react';
import { Nav, NavItem } from 'reactstrap';

import LoginLink from '../LoginLink';
import RegisterLink from '../RegisterLink';
import NavigationAccountMenu from '../../header/NavigationAccountMenu';

@observer
export class AccountLinks extends Component {
  render() {
    let accountLinks;
    if (this.props.accountStore.loggedIn) {
      accountLinks = <NavigationAccountMenu />;
    } else {
      accountLinks = (
        <NavItem>
          <LoginLink />
          <RegisterLink prefix={'/'} />
        </NavItem>
      );
    }
    return <Nav className="AccountLinks">{accountLinks}</Nav>;
  }
}

AccountLinks.propTypes = {
  accountStore: modelOf(AccountStore).isRequired
};

export default inject('accountStore')(AccountLinks);
