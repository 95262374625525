export const storageKeys = {
  wishlist: 'wishlist',
  customerHash: 'survey_hash',
  surveysSeen: 'surveys_seen',
};

export const urlParams = {
  accessToken: 'productListAccessToken',
};

export const globalUserMessageDataKey = {
  globalUserMessage: 'globalUserMessage',
};
