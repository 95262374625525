import { types } from 'mobx-state-tree';

const ShippingDeliveryTimeType = {
  HOURS: 'HOURS',
  BUSINESS_DAYS: 'BUSINESS_DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS'
};

export default ShippingDeliveryTimeType;

export const ShippingDeliveryTimeTypeType = types.enumeration(
  'ShippingDeliveryTimeType',
  Object.values(ShippingDeliveryTimeType)
);
