import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';
import { get } from 'lodash';

import { modelOf } from '../../../prop-types';
import Product from '../../../models/Product';
import AddToCart from '../../cart/AddToCart';
import Analytics from '../../../analytics/Analytics';
import CartStore from '../../../store/CartStore';
import ProductClass from '../../../types/ProductClass';
import ProductAvailabilityType from '../../../types/ProductAvailabilityType';
import AccountStore from '../../../store/AccountStore';
import ConfigStore from '../../../store/ConfigStore';
import ProductSeasonButton from '../ProductSeasonButton';
import ProductWatcherWidget from '../../product-watcher/ProductWatcherWidget';
import RequestState from '../../../types/RequestState';
import SimpleAddToCart from '../../cart/SimpleAddToCart';
import CartIcon from '../../cart/CartIcon';
import {
  calculateNearestProductQuantityWithQuantityStep,
  roundWithPrecision,
} from '../../../util/number';
import UnexpectedError from '../../loader/UnexpectedError';
import ProductRecurringOrder from '../ProductRecurringOrder';
import RecurringOrderStore from '../../../store/RecurringOrderStore';
import UIStore from '../../../store/UIStore';
import CurrencyStore from '../../../store/CurrencyStore';
import ProposalStore from '../../../store/ProposalStore';

export const DEFAULT_MAX_QUANTITY = 99999;

const createPayload = (id, qty) => {
  return {
    id,
    qty,
  };
};

@observer
class ProductAddToCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addToCartError: null,
      messageOK: null,
    };

    // Saving the function as a reference so we don't create a new Math-function on every render.
    this.nearestProductQuantity =
      calculateNearestProductQuantityWithQuantityStep;
    this.mathFloor = Math.floor;
    this.mathMin = Math.min;
  }

  getMaxByPackage = (product) => {
    const { activeProductId } = this.props;
    return product.sellInPackage
      ? this.mathFloor(
          product.getFreeQuantity(activeProductId) / product.package_size
        ) * product.package_size
      : product.getFreeQuantity(activeProductId);
  };

  getMaxQuantity = (product) => {
    const { accountStore, configStore } = this.props;

    const backOrderLimit =
      configStore.product.backorderLimit > 0
        ? configStore.product.backorderLimit
        : DEFAULT_MAX_QUANTITY;

    const normalMaxLimit = product.canBeOrderedOutOfStock
      ? DEFAULT_MAX_QUANTITY
      : this.getMaxByPackage(product);

    const maxAccountQuantity =
      accountStore.account && accountStore.account.max_product_quantity > 0
        ? accountStore.account.max_product_quantity
        : DEFAULT_MAX_QUANTITY;
    const maxBackorderQuantity =
      product.availability_type === ProductAvailabilityType.ALLOW_BACKORDER
        ? backOrderLimit
        : DEFAULT_MAX_QUANTITY;
    const maxNormalProductQuantity =
      product.availability_type !== ProductAvailabilityType.ALLOW_BACKORDER
        ? normalMaxLimit
        : DEFAULT_MAX_QUANTITY;

    return this.mathMin(
      maxAccountQuantity,
      maxBackorderQuantity,
      maxNormalProductQuantity,
      DEFAULT_MAX_QUANTITY
    );
  };

  handleAddToProposal = (quantity) => {
    const {
      accountStore,
      configStore,
      proposalStore,
      addToCartProduct,
      activeProductId,
      analytics,
      position,
      product,
      listId,
      listName,
    } = this.props;

    if (proposalStore.state === RequestState.LOADING) {
      return;
    }

    if (activeProductId) {
      const withTax = accountStore.showPricesWithTax;
      const quantityStep = addToCartProduct.getQuantityStep(product);
      const newQuantity = this.nearestProductQuantity(quantity, quantityStep);
      const value = product.getPrice(withTax, activeProductId);

      proposalStore
        .addToProposal(createPayload(activeProductId, newQuantity))
        .then(() => {
          this.setState({
            loading: false,
            addToCartError: null,
            messageOK: null,
          });

          if (configStore.analytics.ga4.enabled) {
            analytics.addToProposal(
              [
                {
                  product,
                  quantity: newQuantity,
                  activeProductId,
                  position,
                  itemListName: listName,
                  itemListId: listId,
                },
              ],
              roundWithPrecision(value * newQuantity, 4)
            );
          }
        })
        .catch((e) => {
          this.setState({
            loading: false,
            addToCartError: e,
            messageOK: null,
          });
        });
    }
  };

  handleAddToCartClick = (quantity, services = [], newRecurring = {}) => {
    const {
      accountStore,
      activeProductId,
      addToCartProduct,
      analytics,
      cartStore,
      position,
      product,
      productListView,
      listId,
      listName,
    } = this.props;

    // If cartStore is already loading, don't do anything
    if (cartStore.state === RequestState.LOADING) {
      return;
    }

    if (activeProductId) {
      const withTax = accountStore.showPricesWithTax;
      const quantityStep = addToCartProduct.getQuantityStep(product);
      const newQuantity = this.nearestProductQuantity(quantity, quantityStep);
      const value = product.getPrice(withTax, activeProductId);
      const withActiveCategory = productListView !== true;
      this.setState({ loading: true });

      cartStore
        .addToCart(
          activeProductId,
          newQuantity,
          [],
          services,
          newRecurring,
          withActiveCategory
        )
        .then(() => {
          this.setState({
            loading: false,
            addToCartError: null,
            messageOK: null,
          });

          analytics.addToCart(
            [
              {
                product,
                quantity: newQuantity,
                activeProductId,
                position,
                itemListName: listName,
                itemListId: listId,
              },
            ],
            roundWithPrecision(value * newQuantity * quantityStep, 4)
          );

          analytics.cartStatus({
            cart: cartStore.cart,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
            addToCartError: e,
            messageOK: null,
          });
        });
    }
  };

  handleAddToOrderClick = (orderId, quantity, cycle) => {
    const {
      accountStore,
      cartStore,
      recurringOrderStore,
      activeProductId,
      addToCartProduct,
      analytics,
      position,
      product,
      listId,
      listName,
    } = this.props;

    // If cartStore is already loading, don't do anything
    if (recurringOrderStore.state === RequestState.LOADING) {
      return;
    }
    if (activeProductId) {
      const withTax = accountStore.showPricesWithTax;
      const quantityStep = addToCartProduct.getQuantityStep(product);
      const newQuantity = this.nearestProductQuantity(quantity, quantityStep);
      const value = product.getPrice(withTax, activeProductId);

      this.setState({ loading: true });

      const orderProduct = Object.assign(
        {
          quantity: newQuantity,
          extendedId: activeProductId,
        },
        cycle
      );

      recurringOrderStore
        .addProduct(orderId, orderProduct)
        .then(() => {
          this.setState({
            loading: false,
            messageOK: true,
          });
          analytics.addToCart(
            [
              {
                product,
                quantity,
                activeProductId,
                position,
                itemListName: listName,
                itemListId: listId,
              },
            ],
            roundWithPrecision(value * newQuantity * quantityStep, 4)
          );
          analytics.cartStatus({
            cart: cartStore.cart,
          });
        })
        .catch((e) => {
          this.setState({
            loading: false,
            messageOK: false,
          });
        });
    }
  };

  handleRecurringToCart = (newRecurring) => {
    this.handleAddToCartClick(this.props.quantity, [], newRecurring);
  };

  handleRecurringToOrder = (orderId, newRecurring) => {
    this.handleAddToOrderClick(orderId, this.props.quantity, newRecurring);
  };

  updateQuantity = (quantity) => {
    if (this.props.onUpdateProductQuantity) {
      this.props.onUpdateProductQuantity(quantity);
    }
  };

  renderMinOrderQuantityErrorBanner = () => {
    const { quantity, addToCartProduct } = this.props;

    if (
      !addToCartProduct.min_order_quantity ||
      quantity >= addToCartProduct.min_order_quantity
    ) {
      return null;
    }

    return (
      <Alert
        color="light"
        className="ProductAddToCart__minimum-order-quantity-banner"
      >
        <FormattedMessage
          id="product.minOrderQuantityError"
          defaultMessage="Products minimum quantity is {minOrderQuantity} {stockUnit}"
          values={{
            minOrderQuantity: addToCartProduct.min_order_quantity,
            stockUnit: addToCartProduct.stock_unit,
          }}
        />
      </Alert>
    );
  };

  renderError = () => {
    const { addToCartError } = this.state;

    if (!addToCartError) {
      return null;
    }

    let errors = get(addToCartError, 'response.data.errors');

    if (!errors) {
      errors = get(addToCartError, 'response.data');
    }

    if (errors) {
      return (
        <Alert className="ProductAddToCart__errors" color="danger">
          {Object.values(errors).map((message) => (
            <div key={message}>{message}</div>
          ))}
        </Alert>
      );
    }

    return <UnexpectedError />;
  };

  renderOrderMessage = () => {
    const { messageOK } = this.state;

    return messageOK ? (
      <Alert className="ProductAddToCart__errors" color="success">
        <FormattedMessage
          id="order.productAddedSentence"
          defaultMessage="Product added to order"
        />
      </Alert>
    ) : (
      <Alert className="ProductAddToCart__errors" color="danger">
        <FormattedMessage
          id="order.productAddFailedSentence"
          defaultMessage="Product cannot be added for order"
        />
      </Alert>
    );
  };

  render() {
    const {
      cartStore,
      accountStore,
      configStore,
      uiStore,
      product,
      activeProductId,
      addToCartProduct,
      simple,
      withRecurringOrder,
      withServices,
      hideQuantity,
      quantity,
      ifWebStore,
      withQuantityPicker,
      isValid,
      scrollToServices,
      productPageContent,
      ...rest
    } = this.props;

    const { addToCartError, messageOK, loading } = this.state;

    if (product.season && !product.season.hasStarted()) {
      return <ProductSeasonButton season={product.season} />;
    }

    const ifShoppingCenter = configStore.siteConfig.isShoppingCenter;
    const ifBuyingAllowed =
      product.merchantInfo && product.merchantInfo.buyingAllowed;

    if (ifShoppingCenter && !ifBuyingAllowed) {
      return null;
    }
    const withTax = accountStore.showPricesWithTax;
    if (activeProductId && product.isTemporarilyUnavailable(activeProductId)) {
      let productNameWithModel =
        addToCartProduct.name + ' ' + addToCartProduct.model;
      if (product.class === ProductClass.COLLECTION) {
        const collectionItem =
          product.collection.getItemWithProductId(activeProductId);
        if (collectionItem && collectionItem.name) {
          productNameWithModel =
            productNameWithModel + ' – ' + collectionItem.name;
        }
      }
      return !simple ? (
        <ProductWatcherWidget
          price={product.getPrice(withTax)}
          productName={productNameWithModel}
          activeProductId={activeProductId}
        />
      ) : null;
    }

    const onlyInShop =
      addToCartProduct.availability_type ===
      ProductAvailabilityType.ONLY_IN_SHOP;

    const soldOut =
      activeProductId &&
      addToCartProduct.availability_type === ProductAvailabilityType.CLOSEOUT &&
      !product.hasEnoughStockAvailable(activeProductId);

    const hideAddToCart =
      onlyInShop ||
      (product.season && product.season.hasEnded()) ||
      (soldOut &&
        // Don't hide for collection or multi products to avoid confusion caused
        // by arriving to the page and not seeing an add to cart button.
        product.class !== ProductClass.COLLECTION &&
        product.class !== ProductClass.MULTI);

    if (hideAddToCart) {
      return null;
    }

    const disabled = !activeProductId || soldOut || !isValid;

    let disabledMessage;
    if (!activeProductId) {
      disabledMessage = (
        <FormattedMessage
          id="product.selectProductFirst"
          defaultMessage="First choose the product."
        />
      );
    } else if (
      (product.class !== ProductClass.COLLECTION ||
        product.class !== ProductClass.MULTI) &&
      soldOut
    ) {
      disabledMessage = (
        <FormattedMessage
          id="product.currentProductSoldOut"
          defaultMessage="The selected product has been sold out."
        />
      );
    } else if (soldOut) {
      disabledMessage = (
        <FormattedMessage
          id="product.hasBeenSoldOut"
          defaultMessage="This product has been sold out."
        />
      );
    } else if (!isValid) {
      disabledMessage = (
        <FormattedMessage
          id="product.checkingShippingValidity"
          defaultMessage="Product validity for the cart."
        />
      );
    }

    let tooltipMessage;
    const updateInProgress = cartStore.state === RequestState.LOADING;
    if (updateInProgress) {
      tooltipMessage = (
        <FormattedMessage
          id="cart.beingUpdated"
          defaultMessage="Cart is being updated"
        />
      );
    }

    let quantityAllowed =
      accountStore.isRetailer || configStore.productCard.showQuantitySelection;
    if (hideQuantity) {
      quantityAllowed = false;
    }

    let content = null;

    const quantityStep = addToCartProduct.getQuantityStep(product);
    const buyingAllowed = !(
      configStore.recurringOrder.enabled &&
      accountStore.loggedIn &&
      addToCartProduct.hasOnlyRecurringOrder()
    );

    if (!simple) {
      content = (
        <>
          <div className="ProductAddToCart ProductAddToCart--normal">
            {buyingAllowed && (
              <AddToCart
                key={addToCartProduct.id}
                activeProductId={activeProductId}
                notAllowed={updateInProgress}
                disabled={disabled}
                tooltipMessage={disabledMessage || tooltipMessage}
                onAddToCartClick={this.handleAddToCartClick}
                onAddToProposalClick={this.handleAddToProposal}
                maxQuantity={this.getMaxQuantity(addToCartProduct)}
                quantityStep={quantityStep}
                unit={addToCartProduct.stock_unit}
                loading={loading}
                withTax={withTax}
                product={product}
                onUpdateProductQuantity={this.updateQuantity}
                quantity={quantity}
                minOrderQuantity={addToCartProduct.min_order_quantity}
                isProposal={configStore.siteConfig.isOnlyProposal}
                withServices={withServices}
                withQuantityPicker={withQuantityPicker}
                scrollToServices={scrollToServices}
                productPageContent={productPageContent}
                {...rest}
              />
            )}
          </div>
          {configStore.siteConfig.isHybrid && (
            <div className="ProductAddToCart ProductAddToCart--proposal">
              <AddToCart
                key={addToCartProduct.id}
                notAllowed={updateInProgress}
                disabled={disabled}
                tooltipMessage={disabledMessage || tooltipMessage}
                onAddToCartClick={this.handleAddToCartClick}
                onAddToProposalClick={this.handleAddToProposal}
                maxQuantity={this.getMaxQuantity(addToCartProduct)}
                quantityStep={quantityStep}
                unit={addToCartProduct.stock_unit}
                loading={loading}
                withTax={withTax}
                product={product}
                onUpdateProductQuantity={this.updateQuantity}
                quantity={quantity}
                minOrderQuantity={addToCartProduct.min_order_quantity}
                isProposal={true}
                withQuantityPicker={withQuantityPicker}
                withServices={false}
                scrollToServices={scrollToServices}
                productPageContent={productPageContent}
                {...rest}
              />
            </div>
          )}
          {this.renderMinOrderQuantityErrorBanner()}
          {addToCartError && this.renderError()}
          {withRecurringOrder && (
            <ProductRecurringOrder
              product={product}
              withTax={withTax}
              onAddToCartClick={this.handleRecurringToCart}
              onAddToOrderClick={this.handleRecurringToOrder}
              disabled={disabled}
              loading={loading}
            />
          )}
          {withRecurringOrder &&
            messageOK !== null &&
            this.renderOrderMessage()}
        </>
      );
    } else if (simple && !disabled && buyingAllowed) {
      content = quantityAllowed ? (
        <SimpleAddToCart
          notAllowed={updateInProgress}
          loading={loading}
          maxQuantity={this.getMaxQuantity(addToCartProduct)}
          quantityStep={quantityStep}
          onAddToCartClick={this.handleAddToCartClick}
          onAddToProposalClick={this.handleAddToProposal}
          unit={addToCartProduct.stock_unit}
          tooltipMessage={disabledMessage || tooltipMessage}
          disabled={disabled}
          onUpdateProductQuantity={this.updateQuantity}
          minOrderQuantity={addToCartProduct.min_order_quantity}
          quantity={quantity}
          ifWebStore={ifWebStore}
        />
      ) : (
        ifWebStore && (
          <CartIcon
            notAllowed={updateInProgress}
            onClick={() => this.handleAddToCartClick(quantityStep)}
            onProposalClick={() => this.handleAddToProposal(quantityStep)}
            loading={loading}
            tooltipMessage={tooltipMessage}
          />
        )
      );
    }

    return content;
  }
}

ProductAddToCart.propTypes = {
  accountStore: modelOf(AccountStore).isRequired,
  cartStore: modelOf(CartStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  currencyStore: modelOf(CurrencyStore).isRequired,
  recurringOrderStore: modelOf(RecurringOrderStore).isRequired,
  proposalStore: modelOf(ProposalStore).isRequired,
  uiStore: modelOf(UIStore).isRequired,
  analytics: PropTypes.instanceOf(Analytics).isRequired,
  product: modelOf(Product).isRequired,
  addToCartProduct: PropTypes.object.isRequired,
  activeProductId: PropTypes.string,
  listId: PropTypes.string,
  listName: PropTypes.string,
  columnId: PropTypes.number,
  position: PropTypes.number,
  quantity: PropTypes.number,
  rowId: PropTypes.number,
  hideQuantity: PropTypes.bool,
  ifWebStore: PropTypes.bool,
  isValid: PropTypes.bool,
  productPageContent: PropTypes.bool,
  simple: PropTypes.bool,
  withQuantityPicker: PropTypes.bool,
  withRecurringOrder: PropTypes.bool,
  withServices: PropTypes.bool,
  history: PropTypes.object,
  onUpdateProductQuantity: PropTypes.func,
  scrollToServices: PropTypes.func,
  price: PropTypes.node,
};

ProductAddToCart.defaultProps = {
  simple: false,
  hideQuantity: false,
  ifWebStore: true,
  withQuantityPicker: true,
  isValid: true,
};

export default inject(
  'accountStore',
  'cartStore',
  'configStore',
  'currencyStore',
  'recurringOrderStore',
  'uiStore',
  'analytics',
  'recurringOrderStore',
  'proposalStore'
)(ProductAddToCart);
