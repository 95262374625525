import { Component } from 'react';
import { observer, inject } from 'mobx-react';
import RouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import cookies from 'js-cookie';

import { parse } from '../../../util/queryString';

const COOKIE_KEY = 'gclid_start_time';

const setStoredExpiration = (expireInSeconds) => {
  const expiryTimestamp = new Date().getTime() + expireInSeconds * 1000;
  return cookies.set(COOKIE_KEY, expiryTimestamp);
};

const getStoredExpiration = () => {
  return Number(cookies.get(COOKIE_KEY) || 0);
};

const removeStoredExpiration = () => {
  return cookies.remove(COOKIE_KEY);
};

@observer
export class GclidTracker extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    const { gclid, cuad } = parse(location.search);
    this.onRouteChanged(true, gclid || cuad);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // We hook into UNSAFE_componentWillUpdate so that the UI flashes as little as possible.
    if (this.props.location !== nextProps.location) {
      this.onRouteChanged(false);
    }
    return false;
  }

  onRouteChanged(initial, gclid) {
    const { expiration } = this.props;

    if (window.isSSR) {
      return;
    }

    const storedExpiration = getStoredExpiration();
    const hasExpired =
      storedExpiration > 0 && storedExpiration < new Date().getTime();

    if (hasExpired && initial) {
      removeStoredExpiration();
    } else if (storedExpiration === 0 && gclid) {
      setStoredExpiration(expiration);
    } else if (hasExpired) {
      window.location.reload();
    }
  }

  render() {
    return null;
  }
}

GclidTracker.propTypes = {
  expiration: PropTypes.number.isRequired,
  location: RouterPropTypes.location.isRequired
};

export default inject((store) => ({
  expiration: store.configStore.gclid.expirationSeconds
}))(GclidTracker);
