import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import classNames from 'classnames';

import { modelOf } from '../../../prop-types';
import Category from '../../../models/Category';
import RouteService from '../../../services/RouteService';
import ConfigStore from '../../../store/ConfigStore';

const NavigationCategoryButton = ({ configStore, routeService, category }) => {
  return (
    <div key={category.id} className="NavigationCategoryButton">
      <Link className="CategoryLink" to={routeService.getPath(category.path)}>
        <Button
          className={classNames('NavigationCategoryButton__button', {
            ConfigurationInputsRounded: configStore.layout.corners.isRounded,
            ConfigurationInputsBox: configStore.layout.corners.isBoxed,
          })}
          color="secondary"
        >
          {category.navigationName}
        </Button>
      </Link>
    </div>
  );
};

NavigationCategoryButton.propTypes = {
  configStore: modelOf(ConfigStore).isRequired,
  routeService: PropTypes.instanceOf(RouteService).isRequired,
  category: modelOf(Category).isRequired,
};

export default inject(
  'configStore',
  'routeService'
)(observer(NavigationCategoryButton));
