import {
  types,
  getEnv,
  flow,
  resolveIdentifier,
  getRoot
} from 'mobx-state-tree';

import Country from '../models/Country';
import StatefulStore from '../models/StatefulStore';

const CountryStore = StatefulStore.named('CountryStore')
  .props({
    countries: types.optional(types.array(Country), []),
    countryCode: types.maybe(types.number)
  })
  .actions((self) => {
    const setup = (countries, countryCode) => {
      self.countries = countries;
      self.countryCode = countryCode;

      self.setLoading(false);
    };

    const loadCountries = flow(function* loadCountries() {
      self.setLoading(true);

      try {
        self.countries = yield getEnv(self).apiWrapper.request(`countries`);
      } catch (e) {
        self.setError(e);
        throw e;
      }

      self.setLoading(false);
    });

    return {
      setup,
      loadCountries
    };
  })
  .views((self) => {
    return {
      get activeCountry() {
        return self.countryCode ? self.findCountry(self.countryCode) : null;
      },
      findCountry(countryCode) {
        return resolveIdentifier(Country, getRoot(self.countries), countryCode);
      },
      get countryCount() {
        return self.countries.length;
      }
    };
  });

export default CountryStore;
