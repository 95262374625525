import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import AdZones from '../../../types/AdZones';
import { inject, observer } from 'mobx-react';
import { modelOf } from '../../../prop-types';
import ConfigStore from '../../../store/ConfigStore';
import BannerSlider from '../BannerSlider/BannerSliderSlides';
import BannerStyle from '../../../types/banner/BannerStyle';
import AdStore from '../../../store/AdStore';

const FooterBanner = ({ adStore, configStore, overrideSettings }) => {
  useEffect(() => {
    maybeLoadBanners();
  }, []);

  const maybeLoadBanners = () => {
    adStore.loadAds(getAdParameters());
  };

  const getFooterBannerSettings = () => {
    const style = configStore.banner.footerBanner.style;
    let styleSettings = {};

    switch (style) {
      case BannerStyle.INFINITE:
        styleSettings = {
          infinite: true,
        };
        break;
      case BannerStyle.CENTERED:
        styleSettings = {
          centerMode: true,
        };
        break;
      case BannerStyle.BOTH:
        styleSettings = {
          infinite: true,
          centerMode: true,
        };
        break;
      default:
        styleSettings = {};
        break;
    }

    return styleSettings;
  };

  const bannerSettings = {
    ...overrideSettings,
    ...getFooterBannerSettings(),
  };

  const getAdParameters = () => {
    return {
      bannerZones: [AdZones.FOOTER_BANNER],
    };
  };

  return (
    <BannerSlider
      className="FooterBanner"
      adZone={AdZones.FOOTER_BANNER}
      searchParams={getAdParameters()}
      aspectRatio={2.85}
      maximumCrossAxisSize={configStore.banner.footerBanner.height}
      overrideSettings={bannerSettings}
    />
  );
};

FooterBanner.propTypes = {
  adStore: modelOf(AdStore).isRequired,
  configStore: modelOf(ConfigStore).isRequired,
  overrideSettings: PropTypes.object,
};

export default withRouter(
  inject('adStore', 'configStore')(observer(FooterBanner))
);
